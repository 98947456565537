// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    sg_taxi: {
      'ComfortDelgro & Citycab': 'ComfortDelGro & CityCab',
      Premier: 'Premier',
      Prime: 'Prime',
      'SMRT Cab': 'SMRT',
      'Trans-cab': 'Trans-Cab',
      'Yellow-Top': 'Yellow-Top',
      HDT: 'HDT',
    },
    ph_taxi_plate: 'ស្លាកលេខតាក់ស៊ី',
    desc_mdt_must: 'រូបថតMDTតាក់ស៊ីត្រូវបង្ហាញ',
    desc_mdt_name: 'ឈ្មោះអ្នកបើកបរ',
    desc_mdt_plate: 'ស្លាកលេខយានយន្ត',
    desc_mdt_date: 'កាលបរិច្ឆេទថ្មីៗ (ឧ. រូបថតទើបថតថ្មីៗ)',
    btn_save_next: 'រក្សាទុក និងបន្ទាប់',
    btn_later: 'ពេលក្រោយ',
    repeat_password: 'បញ្ចូលលេខសម្ងាត់ម្តងទៀត',
    phone_number: 'លេខទូរសព្ទ',
    digit_pin_number: 'លេខ កូដ ៤ខ្ទង់',
    refferal_code: 'លេខកូដណែនាំ',
    singapore: 'សិង្ហបូរី',
    country: 'ប្រទេស',
    signup: {
      introduce1:
        'សូមស្វាគមន៍មកកាន់ទំព័រចុះឈ្មោះអ្នកបើកបរ TADA ។ សូមផ្តល់ព័ត៌មានដូចខាងក្រោមដើម្បីក្លាយជាជាដៃគូរបស់ TADA ។ សូមបញ្ជូនឯកសារទាំងអស់ដើម្បីបំពេញលិខិតសុំចុះឈ្មោះ។ ប្រសិនបើអ្នកជួបប្រទះបញ្ហាណាមួយជាមួយការដាក់ស្នើសូមទាក់ទងមកយើងខ្ញុំតាមរយៈ',
      email: 'supportkh@tada.global',
      introduce2: 'ហើយយើងខ្ញុំនឹងទាក់ទងទៅអោយអ្នកវិញក្នុងរយៈពេលមួយថ្ងៃធ្វើការ។ ឯកសារអាចត្រូវបានផ្ទុកឡើងជាទម្រង់រូបភាព ឬជា PDF ។',
      submit: 'បញ្ជូន',
      login_here: 'ចូលទីនេះ',
      refferal_code: 'លេខកូដណែនាំ (តាមជម្រើស)',
      pin_number: 'លេខកូដសម្ងាត់',
      mobile_number: 'លេខទូរសព្ទ',
      agree: 'ខ្ញុំយល់ព្រម',
      my_information: 'ព័ត៌មានរបស់ខ្ញុំ',
    },
    driver: {
      available_after_complete: 'អាចបង្ហាញបន្ទាប់ពីបានបញ្ចប់',
      filled_out_zero: 'អ្នកកបានបំពេញ 0/{{totalSteps}} នៃពត៌មាន',
      my_page: 'ទំព័រ​របស់ខ្ញុំ',
      application: 'បែបបទចុះឈ្មោះអ្នកបើកបរ',
      application_taxi: 'កម្មវិធីបញ្ជាតាក់ស៊ី',
      application_tuktuk: 'ពាក្យស្នើសុំបើកបរម៉ូតូ TukTuk',
      application_bike: 'កម្មវិធីបញ្ជាកង់',
      application_not_complete: 'ឯកសារចុះឈ្មោះរបស់អ្នកមិនបានសម្រេចទេ',
      filled_out: 'អ្នកកបានបំពេញ {{doneSteps}}/{{totalSteps}} នៃពត៌មាន',
      being_reviewed: 'ឯកសារចុះឈ្មោះរបស់អ្នកកំពុងត្រូវបានពិនិត្យមើល',
      information: 'ព័ត៌មាន​ផ្ទាល់ខ្លួន',
      name: 'ឈ្មោះ',
      mobile_number: 'លេខទូរសព្ទ',
      refferal_code: 'លេខកូដណែនាំរបស់ខ្ញុំ',
      logout: 'ចេញ',
      application_complete: 'ការចុះឈ្មោះរបស់អ្នកបានសម្រេច។ យើងបានផ្ញើអ៊ីមែលបញ្ជាក់ទៅឱ្យអ្នក។',
      application_not_succesful: 'ការចុះឈ្មោះរបស់អ្នកមិនបានជោគជ័យ។ សូមពិនិត្យអ៊ីម៉ែលរបស់អ្នកហើយបញ្ជូនឯកសារដែលបានបញ្ជាក់ម្តងទៀត។',
    },
    judicial_record: 'កំណត់ត្រាតុលាការ',
    vehicle_registration: 'ការចុះបញ្ជីយានយន្ត',
    tuktuk_registration_card: 'កាតចុះឈ្មោះក្រុមហ៊ុនម៉ូតូទឹកកក',
    certificate_of_registry: 'វិញ្ញាបនបត្រចុះបញ្ជី\n',
    commercial_insurance_certificate: 'វិញ្ញាបនបត្រធានារ៉ាប់រងពាណិជ្ជកម្ម',
    contract_car_badge: 'កិច្ចសន្យាស្លាកសញ្ញារថយន្ត',
    national_id: 'អត្តសញ្ញាណប័ណ្ណ',
    identity_card_number: 'លេខអត្តសញ្ញាណប័ណ្ណ\n',
    license_plate_number: 'ស្លាកលេខ',
    car_make: 'ម៉ាករថយន្ត',
    car_make_ex: 'ម៉ាករថយន្ត (ឧទាហរណ៍៖ BMW)',
    car_model_ex: 'ម៉ូដែលរថយន្ត (ឧទាហរណ៍៖ QM3)',
    bike_make: 'អ្នកជិះកង់',
    bike_model: 'ម៉ូដែលជិះកង់',
    desc_sharp_clear_photo: 'ត្រូវតែច្បាស់ល្អ',
    valid_driving_license: 'ប័ណ្ណបើកបរមានសុពលភាពត្រូវបានផ្ទុកឡើង (មិនផុតកំណត់)',
    valid_document: 'ឯកសារមានសុពលភាពត្រូវបានផ្ទុកឡើង (មិនផុតកំណត់)',
    valid_nric: 'ត្រឹមត្រូវត្រូវតែផ្ទុកឡើង NRIC (ឧ។ មិនផុតកំណត់) ។',
    valid_registration_card: 'កាតចុះឈ្មោះមានសុពលភាពត្រូវតែត្រូវបានផ្ទុកឡើង (ឧ។ មិនបានផុតកំណត់)\n',
    waring_acra_form: 'ផ្ទុកឡើង 3 ទំព័រដំបូងនៃឯកសារដែលបង្ហាញថាអ្នកជាមនុស្សខ្លួនឯងហើយមានក្រុមហ៊ុនចុះបញ្ជីសម្រាប់គោលបំណងប្រមូលប្រាក់ចំណូល។',
    waring_bike_form: 'ផ្លាកអាជ្ញាប័ណ្ណត្រូវតែផ្គូផ្គងជាមួយឯកសារទាំងអស់',
    acra_form_skip: 'ជំហាននេះមិនត្រូវបានតម្រូវដោយអ្នកដែលមានគម្រោងធ្វើប្រតិបត្តិការជាមួយរថយន្តជួលទេ។',
    upload_the_backside: 'ផ្ទុកឡើងផ្នែកខាងក្រោយដែលមានព័ត៌មានអំពីរថយន្ត',
    gps_tracking_device: 'ប្រភេទពាណិជ្ជកម្មជាមួយឧបករណ៍តាមដាន GPS',
    license_plate: 'ស្លាកលេខនៅក្នុងធានារ៉ាប់រងពាណិជ្ជកម្មរបស់អ្នកត្រូវតែដូចគ្នានឹងស្លាកលេខនៅក្នុងការចុះបញ្ជីរបស់អ្នក។',
    national_id_uploaded: 'អត្តសញ្ញាណប័ណ្ណមានសុពលភាពត្រូវបានផ្ទុកឡើង (មិនផុតកំណត់)',
    upload_driving_license_front: 'ផ្ទុកប័ណ្ណបើកបរ (ខាងមុខ)',
    upload_driving_license_back: 'ផ្ទុកប័ណ្ណបើកបរ (ខាងក្រោយ)',
    upload_judicial_record: 'ផ្ទុកកំណត់ត្រាតុលាការ (ខាងមុខ)',
    upload_vehicle_registration: 'ផ្ទុកឡើងការចុះបញ្ចីយានយន្ត (ត្រឡប់មកវិញ)\n',
    upload_certificate_of_registry: 'ផ្ទុកវិញ្ញាបនប័ត្រចុះបញ្ជី (ត្រឡប់មកវិញ)\n',
    upload_insurance: 'ផ្ទុកធានារ៉ាប់រង (ខាងមុខ)',
    upload_insurance_certificate: 'ផ្ទុកវិញ្ញាបនប័ត្រធានារ៉ាប់រង\n',
    upload_nric_front: 'ផ្ទុកអត្តសញ្ញាណប័ណ្ណជាតិ (ខាងមុខ)',
    upload_nric_back: 'ផ្ទុកអត្តសញ្ញាណប័ណ្ណជាតិ (ខាងក្រោយ)',
    upload_file: 'ផ្ទុកឯកសារ',
    upload_pdvl_front: 'ផ្ទុកPDVL (ខាងមុខ)',
    upload_decal: 'ការរចនារថយន្តឈ្នួលឯកជន',
    upload_car_contract_badge: 'ផ្ទុកស្លាកសញ្ញាកិច្ចសន្យារថយន្ត (ខាងមុខ)',
    upload_national_id: 'ផ្ទុកអត្តសញ្ញាណប័ណ្ណ (ខាងមុខ)',
    upload_identity_card_number_front: 'ផ្ទុកឡើងលេខអត្តសញ្ញាណប័ណ្ណ (មុខ)\n',
    upload_identity_card_number_back: 'បញ្ចូលអត្តសញ្ញាណប័ណ្ណលេខ (ត្រឡប់មកវិញ)',
    upload_bike_picture_front: 'ផ្ទុកឡើងរូបភាពជិះកង់ជារួម',
    upload_bike_picture_back: 'ផ្ទុកឡើងរូបភាពជិះកង់មួយផ្សេងទៀតដែលបង្ហាញយ៉ាងហោចណាស់ពាក់កណ្តាលនៃការជិះកង់ជាមួយនឹងស្លាកលេខ',
    uploaded_profile_picture: 'ផ្ទុករូបថតប្រវត្តិរូប',
    upload_tuktuk_registration_card: 'ដាក់ចូលប្រព័ន្ធប័ណ្ណឥណទានទឹកកក (មុខ)\n',
    upload_profile_picture: 'ប្រសិនបើអ្នករំលងជំហាននេះអ្នកនឹងត្រូវបានរំលឹកឱ្យផ្ទុករូបថតប្រវត្តិរូបម្តងទៀតមុនពេលអ្នកចាប់ផ្តើមទទួលយកការងារ។',
    no_wearing_of_accessories: 'ហាមពាក់សម្ភារៈផ្សេងៗ (មួក វ៉ែនតាជាដើម) ដែលអាចគ្របដណ្តប់លើមុខរបស់អ្នក។',
    please_white_background: 'សូមឱ្យផ្ទៃខាងក្រោយមានពណ៌ស។',
    please_upload_photo: 'សូមផ្ទុក រូបថតដែលច្បាស់។',
    taxi_license_plate_num: 'ស្លាកលេខតាក់ស៊ី',
    section_taxi_details: 'ព័ត៌មានលម្អិតរបស់តាក់ស៊ី',
    desc_upload_taxi_front: 'ផ្ទុករូបថតខាងមុខរបស់តាក់ស៊ី',
    desc_upload_mdt: 'ផ្ទុករូបថតMDTរបស់តាក់ស៊ី',
    reset_password_success: 'ពាក្យសម្ងាត់គណនី {{email}}) របស់អ្នកត្រូវបានផ្លាស់ប្តូរដោយសុវត្ថិភាព។',
    change_password: 'ផ្លាស់ប្តូរលេខកូដសម្ងាត់',
    forgot_password: 'ស្វែងរកលេខកូដសម្ងាត់',
    reset_password: 'កំណត់លេខសម្ងាត់ឡើងវិញ',
    error_password_length: 'ពាក្យសម្ងាត់ត្រូវតែមានប្រវែងពី ៦ ទៅ ២០ តួអក្សរ។',
    faq: 'សំណួរដែលសួរញឹកញាប់',
    login: 'បញ្ចូល',
    login_required: 'តម្រូវឱ្យបញ្ចូល',
    logout: 'ចេញ',
    sign_up: 'ចុះឈ្មោះ',
    taxi_sign_up: 'ចុះឈ្មោះជាអ្នកបើកបរតាក់ស៊ី',
    my_page: 'ទំព័រ​របស់ខ្ញុំ',
    email: 'អ៊ីមែល',
    email_placeholder: 'បញ្ចូលអាសយដ្ឋានអ៊ីមែលរបស់អ្នក',
    email_used: 'អ៊ីមែល{{email}}មានហើយ',
    password: 'លេខកូដសម្ងាត់',
    password_placeholder: 'បញ្ចូលលេខកូដសម្ងាត់របស់អ្នក',
    password_confirm: 'បញ្ជាក់លេខកូដសម្ងាត់របស់អ្នក',
    password_confirm_placeholder: 'បញ្ចូលលេខកូដសម្ងាត់ម្តងទៀត',
    new_password_placeholder: 'បញ្ចូលលេខកូដសម្ងាត់ថ្មី',
    verify_mail_title: 'ពិនិត្យអ៊ីមែលបញ្ជាក់',
    verify_mail_desc: 'ពិនិត្យប្រអប់ទទួលសាររបស់អ្នកក្នុងអ៊ីមែលបញ្ជាក់។ ({{email}})',
    privacy_policy: 'គោលការណ៍រក្សាការសម្ងាត់',
    terms_and_conditions: 'លក្ខខណ្ឌនិងលក្ខណៈ',
    button_confirm: 'បញ្ជាក់​',
    button_cancel: 'បោះបង់',
    locale: {
      en: 'English',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      jp: '日本語',
    },
    btn_save: 'រក្សាទុុក',
    first_name: 'នាមខ្លួន',
    last_name: 'នាមត្រកូល',
    phone_placeholder: 'លេខទូរសព្ទ',
    wrong_number_request: '{{driverPhone}} លេខខុស។ សូមបញ្ចូលលេខផ្សេង។\n',
    photo_file_format: '.jpg, .png',
    delete_image: 'លុបរូបភាព',
    upload_image: 'ផ្ទុករូបភាព',
    agree_policy_please: 'សូមយល់ព្រមតាមលក្ខខណ្ឌទាំងអស់។',
    button_resend_email_verify: 'ផ្ញើអ៊ីមែលត្រួតពិនិត្យឡើងវិញ',
    success_resend_email_verify: 'បានផ្ញើឡើងវិញអ៊ីម៉ែលត្រួតពិនិត្យ។',
    optional_brackets: '(តាមជម្រើស)',
    error_time_out: 'ការតភ្ជាប់ទៅប្រព័ន្ធម៉ាស៊ីនមេអស់ពេល។ សូម​ព្យាយាម​ម្តង​ទៀត។',
    error_captcha_not_valid: 'ReCaptcha មិនត្រឹមត្រូវ',
    car_model: 'ម៉ូដែលរថយន្ត',
    car_photo_warn: 'រូបថតផ្ទៃខាងមុខរបស់រថយន្តត្រូវបង្ហាញស្លាកលេខយ៉ាងច្បាស់',
    plate_number: 'ស្លាកលេខ',
    banking_details: 'ព័ត៌មានលំអិតអំពីធនាគារ',
    bank_account_holder_name_failed: 'សូមព្យាយាមម្តងទៀត។ កុំប្រើប្រាស់លេខ និងតួអក្សរពិសេស។',
    bank_account_holder_name: 'ឈ្មោះអ្នកកាន់គណនីធនាគារ',
    bank_select_name: 'ឈ្មោះធនាគារ',
    bank_account_number: 'លេខ​គណនី​ធនាគារ',
    bank_branch: 'សាខា​ធនាគារ',
    bank_skip_warn:
      'ប្រសិនបើអ្នករំលងជំហាននេះអ្នកនឹងត្រូវបានរំលឹកឱ្យផ្តល់ពត៌មានលំអិតអំពី​ធនាគាររបស់អ្នកនៅពេលដែលអ្នកចាប់ផ្តើមទទួលយកការជិះ និងចង់ដកយកប្រាក់ចំណូលរបស់ខ្លួន។',
    warning_when_leaving_without_saving: 'តើអ្នកពិតជាចង់ចាកចេញមែនទេ?',
    upload_bank_picture: 'ផ្ទុករូបភាពរបាយការណ៍ធនាគារ',
    bank_photo_warn: 'សូមដាក់បញ្ចូលរូបថតនូវព័ត៌មានគណនីធនាគារ (ពីបង្កាន់ដៃអេធីអឹមឬរបាយការណ៍ធនាគារ) ដែលបង្ហាញឈ្មោះគណនីនិងលេខគណនីធនាគារ\n',
    profile_picture: 'រូបភាពប្រវត្តិរូប',
    nric: 'អត្តសញ្ញាណប័ណ្ណជាតិ',
    driving_license: 'ប័ណ្ណបើកបរ',
    acra_bizfile: 'ACRA Bizfile',
    insurance_certificate: 'ប័ណ្ណធានារ៉ាប់រង',
    error_password_confirm: 'ពាក្យសម្ងាត់មិនត្រូវគ្នា',
    phone_used: 'លេខទូរស័ព្ទត្រូវបានប្រើរួចហើយ',
    send_pin: 'ផ្ញើកូដ PIN',
    edit: 'កែសម្រួល',
    tada: {
      home:
        '<p>សូមស្វាគមន៍មកកាន់យុគសម័យថ្មីនៃការធ្វើដំណើរ។ សូមស្វាគមន៍មកកាន់ TADA ។<br></p>\n<p>ឈាន​ជើង​ទៅ​កាន់​ពិភព​លោក​ថ្មី​ជាមួយ TADA ដែល​ជា​កន្លែង​ដែល​សេវាកម្មនៃ​ការធ្វើដំណើរ​ត្រូវ​បាន​កំណត់​ឡើង​វិញ។<br></p>\n<p>កន្លែងដែលអ្នកបើកបររីកចម្រើនដោយការមិនគិតកម្រៃជើងសារ។<br></p>\n<p>កន្លែងដែលអ្នកជិះទទួលបានអត្ថប្រយោជន៍ជាមួយតម្លៃច្បាស់លាស់ សមរម្យ សម្រាប់ការជិះប្រកបដោយសុវត្ថិភាព គ្រប់ពេលវេលា និងគ្រប់ទីកន្លែង។<br></p>\n<p>ចាប់ផ្តើមជាមួយ​ដំណើរ​ ដែល​មានភាព​ងាយស្រួល ជឿជាក់ និង​ការ​ទុក​ចិត្ត​ដើម្បីផ្លាស់ប្តូរបទពិសោធន៍នៃការ​ធ្វើដំណើររបស់​អ្នក។</p>',
      driver: {
        app: {
          name_1: 'ស្វែងរក',
          name_2: 'ទទួលភ្ញៀវ',
          name_3: 'បើកបរ',
          name_4: 'ដាក់ភ្ញៀវចុះ និងទូទាត់',
          waiting: '"កំណែiOSនៃកម្មវិធីអ្នកបើកបរ TADA បច្ចុប្បន្នត្រូវបានពិនិត្យឡើងវិញ។ យើងនឹងធ្វើឱ្យប្រាកដថាអ្នកអាចទាញយកកម្មវិធីបានឆាប់រហ័ស! "',
        },
        info: {
          title_1: 'ដំបូងនិងតែមួយគត់ដែលមិនគិតកម្រៃជើងសារសម្រាប់អ្នកបើកបរ',
          content_1:
            'TADA មិនយកកម្រៃជើងសារ។ លើក​លែង​តែ​ថ្លៃ​ប្រើប្រាស់កម្ម​វិធី​ និង ថ្លៃការធ្វើប្រតិបត្តិការសម្រាប់​ប័ណ្ណ​ឥណពន្ធ/ឥណទាន។ ប្រាក់ចំណូលសុទ្ធពីការធ្វើដំណើរគឺសម្រាប់អ្នកបើកបរ។ ​យើងនឹង​រក្សា​ការកំណត់​នេះជានិច្ច។',
          title_2: 'ងាយស្រួល ក្នុងការចុះបញ្ជីជាអ្នកបើកបរ',
          content_2: 'យើងបានសម្រួលដំណើរការចុះឈ្មោះសម្រាប់ភាពងាយស្រួលរបស់អ្នក។',
          title_3: 'ប្រព័ន្ធស្វែងរកកម្រិតខ្ពស់',
          content_3:
            'TADA វិភាគពីគំរូនៃសេវាកម្មរបស់អ្នកបើកបរ ផ្អែកលើទិន្នន័យធំៗ និងផ្តល់ជូនប្រព័ន្ធតភ្ជាប់ផ្ទាល់ខ្លួនដើម្បីឱ្យអ្នកជិះជាច្រើនអាចត្រូវបានតភ្ជាប់ទៅនឹងអ្នកបើកបរ។',
          title_4: 'សេវាកម្មទទួលភ្ញៀវជាបន្តបន្ទាប់',
          content_4:
            'អ្នកអាចទទួលបានការងារផ្សេងទៀតខណៈពេលដែលមានអ្នកជិះកំពុងធ្វើដំណើរជាមួយអ្នក។ នៅពេលអ្នកជិតដល់គោលដៅ អ្នក​អាច​នឹង​ទទួល​បាន​ការងារ​ជា​បន្តបន្ទាប់​ដោយ​មិន​ដោយមិនបាច់រង់ចាំ។',
          title_5: 'ងាយស្រួលចូលទៅកាន់ព័ត៌មានប្រតិបត្តិការ',
          content_5:
            'យើងផ្តល់មុខងារផ្ទាំងគ្រប់គ្រងដែលអនុញ្ញាតឱ្យអ្នកធ្វើការពិនិត្យព័ត៌មានយ៉ាងងាយស្រួល ដូចជាប្រវត្តិការងាររបស់អ្នក ជាប្រចាំថ្ងៃ និងប្រចាំសប្តាហ៍ ប្រាក់ចំណូលបច្ចុប្បន្នរបស់អ្នក និងច្រើនទៀត។',
          title_6: 'រង្វាន់ និង ប្រាក់លើកទឹកចិត្ត',
          content_6: 'យើងមានផ្តល់ប្រាក់លើកទឹកចិត្ត និងកម្មវិធីបេសកកម្ម\nដើម្បីបង្កើនប្រាក់ចំណូលរបស់អ្នក។',
        },
        driverappdownload: 'ទាញយកកម្មវិធីអ្នកបើកបរ',
      },
      rider: {
        app: {
          name_1: 'ស្នើសុំការធ្វើដំណើរ',
          name_2: 'កំពុងស្វែងរកអ្នកបើកបរ',
          name_3: 'ការធ្វើដំណើរ',
          name_4: 'ទូទាត់ & ផ្ដល់មតិ',
        },
        info: {
          title_1: 'ការធ្វើដំណើរលឿនជាងមុនសម្រាប់អ្នកដំណើរ',
          content_1:
            'យើងភ្ជាប់អ្នកជាមួយអ្នកបើកបរយ៉ាងឆាប់រហ័សដោយផ្អែកលើបច្ចេកវិទ្យាដែលតភ្ជាប់យ៉ាងល្អ។ យើងតភ្ជាប់អ្នកជាមួយអ្នកបើកបរដែលនៅជិតបំផុតហើយផ្តល់សេវាកម្មដែលមានគុណភាពខ្ពស់ និងការធ្វើដំណើរដែលមានសុវត្ថិភាព។',
          title_2: 'ថ្លៃធ្វើដំណើរកាន់តែប្រសើរ និងតម្លៃសមរម្យ',
          content_2: 'ការសន្សំសំចៃ និងអាចទុកចិត្តបាន ជាមួយនឹងគោលការណ៍មិនគិតកម្រៃជើងសាររបស់យើង។',
          title_3: 'ការតភ្ជាប់ក្នុងអំឡុងពេលមម៉ាញឹក',
          content_3: 'យើងភ្ជាប់​អ្នក​ជាមួយ​នឹង​អ្នក​បើក​បរមិនថា​ក្នុង​ម៉ោងមម៉ាញឹក ពេលភ្លឺ ឬភ្លៀង​។',
          title_4: 'តំបន់សេវាកម្មគឺធំទូលាយ',
          content_4: 'មិនថាអ្នកទៅទីណាទេ យើងអាចភ្ជាប់អ្នកជាមួយអ្នកបើកបរ\nដើម្បីនាំអ្នកទៅទីនោះដោយសុវត្ថិភាព។',
          title_5: 'ការទូទាត់រហ័ស និងងាយស្រួល',
          content_5:
            'អ្នកអាចជ្រើសរើសការទូទាត់ជាសាច់ប្រាក់ ឬប័ណ្ណឥណពន្ធ/ឥណទាន។ ជ្រើសរើសភ្ជាប់ប័ណ្ណឥណពន្ធ/ឥណទានដែលអ្នកចូលចិត្តសម្រាប់ការទូទាត់ដោយរលូន។',
          title_6: 'ការគ្រប់គ្រងសម្រាប់មតិយោបល់',
          content_6:
            'អ្នកអាចចែករំលែកមតិកែលម្អសម្រាប់អ្នកបើកបរ។ យើងនឹងរៀបចំអ្នកបើកបរដោយផ្អែកលើមតិកែលម្អរបស់អ្នក ដើម្បីភ្ជាប់អ្នកជាមួយនឹងអ្នកបើកបរដែលមានគុណភាពខ្ពស់។',
        },
        riderappdownload: 'ទាញយកកម្មវិធីសម្រាប់អ្នកដំណើរ',
      },
      contact: {
        contact_us: 'ទាក់ទងយើងខ្ញុំ',
        name: {
          label: 'ឈ្មោះឬឈ្មោះហៅក្រៅ (ទាមទារ)',
          placeholder: '(៣០ តួអក្សរ)',
        },
        email: {
          label: 'អាសយដ្ឋានអ៊ីមែលរបស់អ្នក (ទាមទារ)',
          example: '<ឧ. mvler@mvlchain.io)',
          placeholder: {
            name: 'mvler',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: 'ចំណងជើង (ទាមទារ)',
          placeholder: '(៣០ តួអក្សរ)',
        },
        content: {
          label: 'ការពិពណ៌នា (ទាមទារ)',
          placeholder:
            "[ចំណាំ] \\n\\n-អតិបរមា ២០០០ តួអក្សរ \\n- សូមពន្យល់លម្អិត \\n- សូមបញ្ឈប់ពាក្យដែលមិនបានល្អ \\n- សូមដាក់កម្រិតការបង្ហោះសារផ្ទាល់ខ្លួន ( ID, ព័ត៌មានទំនាក់ទំនង ... ល។ ) \\n- ប្រសិនបើយើងត្រូវការព័ត៌មានបន្ថែមយើងនឹងទាក់ទងអ្នក។ \\n- ដោយចុចប៊ូតុង 'ដាក់ស្នើ' ខាងក្រោមអ្នកយល់ព្រមនិងទទួលយកការផ្តល់ជូន។ ផ្តល់ព័ត៌មានអំពីទីតាំង អាយភី ប្រព័ន្ធប្រតិបត្តិការ។ ល។",
        },
        submit: {
          request: {
            submit: 'ផ្ញើ',
            title: 'តើអ្នកប្រាកដជាចង់ផ្ញើមែនទេ?',
            desc: '(អាស័យដ្ឋានអ៊ីម៉ែល:',
            review: 'វាយតម្លៃ',
          },
          confirm: {
            title: 'ការចុះឈ្មោះបានបញ្ចប់',
            desc: 'យើងនឹងឆ្លើយតបទៅនឹងសំណួររបស់អ្នកពេលត្រួតពិនិត្យរួច។',
            confirm: 'ការបញ្ជាក់',
          },
        },
      },
    },
    title: {
      about: 'អំពីយើង',
      driver: 'ដៃគូបើកបរ',
      rider: 'អ្នកជិះ',
      contact: 'ទំនាក់ទំនងយើង',
      faq: 'សំណួរសួរញឹកញាប់',
    },
    safety: 'សុវត្ថិភាព',
    title_otp_request: 'ដើម្បីបន្ត សូមចូលទៅកាន់គណនីរបស់អ្នកសម្រាប់ការផ្ទៀងផ្ទាត់។',
    label_mobile_number: 'លេខទូរសព្ទ',
    label_code_number: 'លេខ​កូដ',
    btn_send: 'ផ្ញើ',
    btn_continue: 'បន្ត',
    error_verification_code: 'លេខកូដបញ្ជាក់មិនត្រូវគ្នា',
    label_account_deletion: 'លុបគណនីរបស់អ្នកចោល',
    label_reason_delete_account: 'ហេតុផលសម្រាប់ការលុបគណនី',
    hint_min_character: 'យ៉ាងតិច {{count}} តួអក្សរ',
    btn_submit: 'បញ្ជូន',
    dialog_account_deletion_desc: 'អ្នក​នឹង​មិន​អាច​បន្តប្រើប្រាស់​សេវាកម្ម TADA​ បាននោះទេ។',
    apply_account_deletion_title: 'យើងកំពុងដំណើរការលុបគណនីរបស់អ្នក។',
    apply_account_deletion_desc: 'ដំណើរការលុបគណនីអាចចំណាយពេលច្រើនថ្ងៃដើម្បីបញ្ចប់។',
    dialog_account_deletion_failure_title: 'ការលុបគណនីរបស់អ្នកមិនជោគជ័យ',
    content_delete_account_error_balance_remaining:
      'សមតុល្យត្រូវតែ 0 ដើម្បីលុបគណនី។ សូមព្យាយាមម្តងទៀតបន្ទាប់ពីដកអ្វីដែលនៅសេសសល់ ឬផ្ញើអ៊ីមែលទៅមជ្ឈមណ្ឌល CS ដើម្បីទទួលបានជំនួយ។',
    content_delete_account_error_exist_rider_penalty_fee:
      'យើងមិនអាចធ្វើការលុបគណនីរបស់អ្នកបានទេ ដោយសារមានការពិន័យលើគណនីរបស់អ្នក។ សូមទាក់ទងក្រុមសេវាកម្មអតិថិជនរបស់យើងតាមរយៈអ៊ីមែល ហើយក្រុមការងាររបស់យើងនឹងជួយក្នុងដំណើរការលុបរបស់អ្នក។',
    content_delete_account_error_exist_active_ride:
      'បច្ចុប្បន្នអ្នកកំពុងមានការកក់។ ដំណើរការលុបគណនីរបស់អ្នកអាចត្រូវបានធ្វើបន្ទាប់ពីបញ្ចប់ ឬលុបចោលការកក់បច្ចុប្បន្នរបស់អ្នក។',
    label_cs_center: 'ក្រុមការងារបម្រើអតិថិជន',
    label_notice: 'សេចក្តីជូនដំណឹង',
    label_get_rewarded: 'ទទួលបានរង្វាន់',
    label_for_me: 'សំរាប់អ្នក',
    label_for_my_friend: 'សំរាប់មិត្តអ្នក',
    label_invite_rider: 'ណែនាំអ្នកជិះ នឹងទទួលបានរង្វាន់',
    text_contact_description:
      'យើងមានប្រតិបត្តិការនៅទូទាំងតំបន់អាស៊ីប៉ាស៊ីហ្វិក ការិយាល័យរបស់យើងមាននៅសិង្ហបុរី កម្ពុជា ថៃ វៀតណាម និងហុងកុង ដែលត្រៀមខ្លួនរួចជាស្រេចដើម្បីផ្ដល់ជំនួយ។ សូមទំនាក់ទំនងមកយើងនៅក្នុងទីតាំងណាមួយ​ ដែលឋិតនៅជិតលោកអ្នក។',
    text_contact_empty_description: 'សូមចុចលើចំណុចនៃការិយាល័យរបស់យើងដែលស្ថិតនៅលើផែនទីដើម្បីមើលព័ត៌មានលម្អិត។',
    report: {
      label_report_issue: 'រាយការណ៏អំពីបញ្ហា',
      title_report_issue: 'តើបញ្ហារបស់អ្នកត្រូវបានដោះស្រាយហើយឬនៅ?',
      content_report_issue: 'សម្រាប់ជំនួយបន្ថែមសូមចុចលើ "រាយការណ៍អំពីបញ្ហា" ដើម្បីចែករំលែកព័ត៌មានបន្ថែម។',
      label_report_an_issue: 'រាយការណ៍ពីបញ្ហាណាមួយ',
      label_describe_issue: 'ពិពណ៌នាអំពីបញ្ហា',
      label_minimum_character: 'ចំនួនតួអក្សរជា អប្បបរិមា {{minLength}}',
      title_cancel_modal: 'បោះបង់ការរាយការណ៏?',
      content_cancel_modal: 'របាយការណ៍របស់អ្នកនឹងមិនត្រូវបានរក្សាទុកទេ។ \nតើអ្នកនៅតែចង់ចាកចេញពីទំព័រនេះទេ? ',
      button_cancel_no: 'កុំបោះបង់',
      title_submit_modal: 'សូមអរគុណចំពោះការឆ្លើយតបរបស់អ្នក!',
      content_submit_modal: 'យើងអាចនឹងទាក់ទងទៅអ្នក ប្រសិនបើយើងត្រូវការពត៌មានបញ្ជាក់បន្ថែមពីអ្នក។',
      button_ok: 'យល់ព្រម',
    },
    car_type_economy: 'Classic',
    car_type_economy_large: 'SUV',
    car_type_premium: 'Premium',
    car_type_premium_large: 'Premium Large',
    car_type_taxi: 'Standard Taxi',
    car_type_taxi_large: 'Standard Large Taxi',
    car_type_premium_taxi: 'Premium Taxi',
    car_type_premium_taxi_large: 'Premium Large Taxi',
    car_type_tuktuk: 'ម៉ូតូកង់៣\n',
    car_type_bike: 'ម៉ូតូកង់៣',
    car_type_remorque: 'រុឺម៉ក',
    car_type_delivery_van: 'ទ្បានដឹកជញ្ជូន',
    car_type_onion_tuktuk: 'កង់បីអគ្គិសនី',
    car_type_delivery_bike: 'ម៉ូតូដឹកទំនិញ',
    product_type_ph: 'រថយន្តឯកជន',
    product_type_taxi: 'តាក់ស៊ី',
    product_type_anytada: 'AnyTADA',
    product_seat_count: '{{seat}} កៅអី',
    economy_cm: 'រថយន្ត',
    economy_large_cm: 'SUV',
    car_type_bus: 'រថយន្តក្រុង',
    car_type_mini_bus: 'រថយន្តក្រុងតូច',
    ride_option_pet_carrier: 'TADA សត្វចិញ្ចឹម(មានទ្រុង)',
    ride_option_pet_leashed: 'TADA សត្វចិញ្ចឹម(ចងខ្សែ)',
    fare_type_app_metered_fare: 'តម្លៃម៉ែត្រ',
    fare_type_car_metered_fare: 'Car Metered',
    fare_type_fixed_fare: 'តម្លៃថេរ',
    car_type_win_bike: 'Win Bike',
    car_type_premium_bike: 'TADA Bike Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'កន្លែងដាក់កៅអីរុញ',
    car_type_tada_bike: 'ម៉ូតូ',
  },
};
