import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-i18next'
import autoBind from 'react-autobind'
import { compose } from 'recompose'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'

import actions from '../actions'
import { getAuthenticationToken } from '../utils/authentication'
import { getLocale } from '../selectors'
import EmailVerifyModal from './Pages/DriverLoginPage/EmailVerifyModal'
import { ToastContainer } from 'react-toastify'
import { captureException, mvlWordBreakSetting } from '../utils/MyUtils'

const Box = styled.div`
  ${({ height }) => height && `height: ${height};`}
  word-break: ${props => mvlWordBreakSetting(props)};
`

export const ToastContainerBox = styled(ToastContainer)`
  word-break: ${props => mvlWordBreakSetting(props)};
`

class Init extends Component {
  constructor(props) {
    super(props)

    autoBind(this)
  }

  state = {
    showVerifyEmailModal: false,
    email: ''
  }

  componentWillMount() {
    const { saveUserInfo, loginSuccess, logoutSuccess, fetchMeRequest, i18n } = this.props
    const tokenObj = getAuthenticationToken()
    if (tokenObj && tokenObj.accessToken) {
      fetchMeRequest()
        .then(res => {
          saveUserInfo(res)
          const isUser = res._model_name === 'User'

          if (isUser) {
            if (res.emailVerified) {
              loginSuccess(tokenObj)
            } else {
              this.setState({
                showVerifyEmailModal: true,
                email: res.email
              })
              logoutSuccess()
            }
          } else {
            loginSuccess(tokenObj)
          }
        })
        .catch(() => {
          logoutSuccess()
        })
    }
    i18n.changeLanguage(this.props.selectedLocale)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLocale && nextProps.selectedLocale !== this.props.selectedLocale) {
      this.props.i18n.changeLanguage(nextProps.selectedLocale)
    }
  }

  componentDidCatch(error, errorInfo) {
    const isProd = process.env.NODE_ENV === 'production'
    if (isProd) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key])
        })
        captureException(error)
      })
    }
  }

  openSuccess(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.setState({
      showVerifyEmailModal: true
    })
  }

  closeSuccess(e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.setState({
      showVerifyEmailModal: false
    })
  }

  render() {
    const { children, selectedLocale, height } = this.props
    return (
      <Box selectedLocale={selectedLocale} height={height}>
        {children}
        {this.state.email && (
          <EmailVerifyModal
            isOpen={this.state.showVerifyEmailModal}
            openModal={this.openSuccess}
            closeModal={this.closeSuccess}
            email={this.state.email}
          />
        )}
        <ToastContainerBox
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          style={{
            zIndex: 9999
          }}
          className="toast-box-cls"
          toastClassName="toast-cls"
          bodyClassName="toast-body-cls"
          selectedLocale={selectedLocale}
        />
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

const mapDispatchToProps = actions

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), translate())

export default enhance(Init)
