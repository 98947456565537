import qs from 'qs'
import {
  CAR_TYPE,
  CAR_TYPE_ICON,
  CAR_TYPE_STRING,
  FAMILY_LARGE_CAR_TYPES,
  PRODUCT_TYPE,
  PRODUCT_TYPE_STRINGS,
  RIDE_OPTION_STRING,
  RIDE_OPTION_TYPE
} from './constants'
import { get } from '../../../utils/api'
import { REGION, TOKEN_KEYS } from '../../../constants/constants'
import anyIcon from '../../../assets/images/RideProduct/any.svg'
import iontadaIcon from '../../../assets/images/RideProduct/iONTADA.svg'
import carIcon from '../../../assets/images/RideProduct/car.svg'
import carPetIcon from '../../../assets/images/RideProduct/car-pets.svg'
import carJuniorIcon from '../../../assets/images/RideProduct/car-junior.svg'
import carJuniorLargeIcon from '../../../assets/images/RideProduct/car-junior-large.svg'
import carWheelchairIcon from '../../../assets/images/RideProduct/car-wheelchair.svg'
import hkNormalTaxiIcon from '../../../assets/images/RideProduct/hk-normal-taxi.svg'
import hkLargeTaxiIcon from '../../../assets/images/RideProduct/hk-large-taxi.svg'


export const getCarImage = product => {
  const availableRideOptionTypes =
    product.availableRideOptions?.map(availableRideOption => availableRideOption.type) ?? []

  if (
    availableRideOptionTypes.includes(RIDE_OPTION_TYPE.PET_CARRIER) ||
    availableRideOptionTypes.includes(RIDE_OPTION_TYPE.PET_LEASHED)
  ) {
    return carPetIcon
  }

  if (product.wheelchairSpaceProduct) {
    return carWheelchairIcon
  }

  if(product.region === REGION.HK && +product.productType === PRODUCT_TYPE.TAXI){
    return [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM, CAR_TYPE.CAR_TYPE_TAXI_STANDARD].includes(+product.carType) ? hkNormalTaxiIcon : hkLargeTaxiIcon
  }

  if (product.familyProduct) {
    if (FAMILY_LARGE_CAR_TYPES.includes(product.carType)) {
      return carJuniorLargeIcon
    }

    return carJuniorIcon
  }

  switch (product.productType) {
    case PRODUCT_TYPE.ANYTADA:
      return anyIcon
    case PRODUCT_TYPE.EV:
      return iontadaIcon
    default:
      return CAR_TYPE_ICON[product.carType] || carIcon
  }
}

export const getCarName = product => {
  if (product.region === REGION.KH) {
    switch (product.carType) {
      case CAR_TYPE.CAR_TYPE_SEDAN:
        return 'economy_cm'
      case CAR_TYPE.CAR_TYPE_VAN:
        return 'economy_large_cm'
    }
  }

  const availableRideOptionTypes =
    product.availableRideOptions?.map(availableRideOption => availableRideOption.type) ?? []

  if (availableRideOptionTypes.includes(RIDE_OPTION_TYPE.PET_CARRIER)) {
    return RIDE_OPTION_STRING[RIDE_OPTION_TYPE.PET_CARRIER]
  }

  if (availableRideOptionTypes.includes(RIDE_OPTION_TYPE.PET_LEASHED)) {
    return RIDE_OPTION_STRING[RIDE_OPTION_TYPE.PET_LEASHED]
  }

  if (product.wheelchairSpaceProduct) {
    return 'car_wheelchair_space_type'
  }

  if (product.familyProduct) {
    if (FAMILY_LARGE_CAR_TYPES.includes(product.carType)) {
      return 'car_junior_large_type'
    }

    return 'car_junior_type'
  }

  switch (product.productType) {
    case PRODUCT_TYPE.ANYTADA:
      return PRODUCT_TYPE_STRINGS[PRODUCT_TYPE.ANYTADA]
    case PRODUCT_TYPE.EV:
      return PRODUCT_TYPE_STRINGS[PRODUCT_TYPE.EV]
    default:
      return CAR_TYPE_STRING[product.carType] || CAR_TYPE_STRING[CAR_TYPE.CAR_TYPE_SEDAN]
  }
}

export const getInformationList = async (codes, language) => {
  const query = qs.stringify(
    {
      productCodes: codes
    },
    {
      arrayFormat: 'repeat'
    }
  )
  const { content } = await get(`/ridesvc/product_information?${query}`, undefined, TOKEN_KEYS.faq)

  return codes
    .map(code => {
      return content.reduce((acc, cur) => {
        if (acc) return acc

        const product = cur.products.find(product => product.code === code)

        if (!product) return null

        const content =
          cur.contents.find(content => content.locale === language) ||
          cur.contents.find(content => content.locale === 'en') ||
          cur.contents[0]

        return {
          id: cur.id,
          code,
          product,
          content
        }
      }, null)
    })
    .filter(Boolean)
}

export const sendIsScrollTopToApp = isScrollTop => {
  if (window.android && window.android.getIsScrollTop) {
    window.android.getIsScrollTop(isScrollTop)
  }
}
