import React from 'react'
import { useParams } from 'react-router'
import { LiveRide } from 'tada-homepage-component'

import * as MapCarIcons from '../../../assets/images/liveRide/map-car-icons'
import appLogo from '../../../assets/images/liveRide/tada-app.svg'
import cross from '../../../assets/images/liveRide/cross.svg'
import star from '../../../assets/images/liveRide/star-filled.svg'
import currentLocation from '../../../assets/images/liveRide/current-location.svg'
import expiredLinkIcon from '../../../assets/images/liveRide/expired-link.svg'
import pickupPin from '../../../assets/images/liveRide/pickup-pin.svg'
import dropOffPin from '../../../assets/images/liveRide/dropoff-pin.svg'
import targetPin from '../../../assets/images/liveRide/target-point-pin.svg'
import pickup from '../../../assets/images/liveRide/pickup.svg'
import dropOff from '../../../assets/images/liveRide/dropoff.svg'
import additionalStop from '../../../assets/images/liveRide/additional-stop.svg'

function RideShow() {
  const params = useParams() // { id: string }
  const serverHost = import.meta.env.VITE_SERVER_HOST || 'https://backend.driver.tada-dev.com'

  return (
    <LiveRide
      googleApiKey={import.meta.env.VITE_GOOGLE_API_KEY}
      branchKey={import.meta.env.VITE_BRANCH_KEY}
      images={{
        mapBike: MapCarIcons.BikeIcon,
        mapETukTuk: MapCarIcons.EtuktukIcon,
        mapIontadaIcon: MapCarIcons.IontadaIcon,
        mapPh: MapCarIcons.PhIcon,
        mapRemoque: MapCarIcons.RemoqueIcon,
        mapTaxi: MapCarIcons.TaxiIcon,
        mapTukTuk: MapCarIcons.YellowTuktukIcon,
        mapBikeWithHuman: MapCarIcons.BikeWithHuman,
        mapHkTaxi: MapCarIcons.HKTaxi,
        mapHkTerritoriesTaxi: MapCarIcons.HKTerritoriesTaxi,
        appLogo,
        cross,
        star,
        currentLocation,
        expiredLinkIcon,
        pickupPin,
        dropOffPin,
        targetPin,
        pickup,
        dropOff,
        additionalStop
      }}
      serverHost={serverHost}
      urlId={params.id}
    />
  )
}

export default RideShow
